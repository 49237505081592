import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import { DAEMON } from '@dbh/redux-extra';
import { makeSelectLocale, makeSelectCountry, COUNTRY_ANDOR_LOCALE_CHANGED } from '@dbh/routing-redux';
import { SSR_STARTED } from '@dbh/ssr-data-redux';
import Sentry from '@dbh/sentry';
import { makeSelectCurrentDateFnsLocaleData, getDateFnsLocaleData, fnsLocaleDataLoaded, REDUX_AND_SAGA_KEY } from './index.js';
import '@reduxjs/toolkit';
import 'immutable';
import 'prop-types';
import '@dbh/with-conforms-to-for-production-www';
import 'reselect';
import '@dbh/reselect-extra';

function*handleLoadDateFnsLocale(a){try{let{payload:{country:b,locale:c}={}}=a;c||(c=yield select(makeSelectLocale())),b||(b=yield select(makeSelectCountry()));const d=yield select(makeSelectCurrentDateFnsLocaleData());if(d)return;const e=yield call(getDateFnsLocaleData,c);yield put(fnsLocaleDataLoaded({country:b,locale:c,dateFnsLocale:e}));}catch(a){Sentry.captureException(a);}}

function*rootSaga(){yield all([takeLatest(COUNTRY_ANDOR_LOCALE_CHANGED,handleLoadDateFnsLocale),takeLatest(SSR_STARTED,handleLoadDateFnsLocale)]);}const injectedSagaConfiguration={key:REDUX_AND_SAGA_KEY,mode:DAEMON};

export { rootSaga as default, injectedSagaConfiguration };
